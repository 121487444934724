<template>
  <div id="navbar-custom">
    <b-navbar>
      <b-button v-if="isLogin" v-b-toggle.side-menu variant="transparent">
        <div class="hamberger" />
        <div class="hamberger" />
        <div class="hamberger" />
      </b-button>
      <b-navbar-brand>
        <img src="@/static/images/logo-default.png" alt="logo" />
      </b-navbar-brand>
    </b-navbar>

    <b-sidebar id="side-menu" title="Sidebar" no-header shadow backdrop @change="toggleBodyScrollbar">
      <div>
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item href="/history"> ประวัติใบคำขอ </b-nav-item>
            <b-nav-item v-b-toggle.collapse-side-menu @click="visible = !visible">
              จัดการผู้ใช้งาน
              <div class="arrow ml-2" :class="{ up: visible, down: !visible }" style="border-color: #ffffff" />
            </b-nav-item>
            <b-collapse id="collapse-side-menu">
              <b-nav-item href="/staff-management/index"> ผู้ใช้งานหน้าบ้าน </b-nav-item>
              <b-nav-item href="/cms-management/index"> ผู้ใช้งานหลังบ้าน </b-nav-item>
            </b-collapse>
            <b-nav-item href="/renew/history"> ต่ออายุกรมธรรม์ </b-nav-item>
            <b-nav-item href="/organi-management/index"> องค์กรในเครือ </b-nav-item>
            <b-nav-item href="/upload-payment/view"> อัพโหลดการชำระเงิน </b-nav-item>
            <b-nav-item href="/upload-payment-file-odd/view"> อัพโหลดไฟล์ ODD </b-nav-item>
            <b-nav-item @click="$router.push({ name: 'tamjaiConnect' })">Tamjai Connect</b-nav-item>
            <b-nav-item @click="logout"> ออกจากระบบ </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: 'NavbarCustom',
  data() {
    return {
      visible: true,
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters['staff/loggedIn']
    },
  },
  methods: {
    toggleBodyScrollbar(visible) {
      const body = document.getElementsByTagName('body')[0]

      if (visible) body.classList.add('overflow-hidden')
      else body.classList.remove('overflow-hidden')
    },

    logout() {
      this.$store.dispatch('staff/destroyAuth')
    },
  },
}
</script>

<style>
#navbar-custom .navbar {
  padding: 0.5rem 2rem;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

#navbar-custom .navbar-brand {
  margin-left: 16px;
}

#navbar-custom .btn {
  padding: 3px 6px !important;
}

#navbar-custom .b-sidebar {
  top: 60px !important;
  background-color: #405f88 !important;
}

#navbar-custom .b-sidebar li {
  padding: 0.75rem !important;
  text-align: left !important;
  border: 1px solid #405f88 !important;
  background-color: #032c63 !important;
}

#navbar-custom .b-sidebar li:hover,
#navbar-custom .b-sidebar #collapse-side-menu li:hover {
  background-color: #405f88 !important;
}

#navbar-custom .b-sidebar #collapse-side-menu li {
  background-color: #001f48 !important;
}

#navbar-custom .b-sidebar li a {
  color: #fff !important;
}

#navbar-custom img {
  padding: 12px 0;
  height: 100px;
  max-height: 100%;
}

#navbar-custom div.hamberger {
  width: 26px;
  height: 3px;
  margin: 6px 0;
  background-color: #032d64;
}
</style>
