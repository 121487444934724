import axios from 'axios'

/**
 * initial state
 */
const state = () => ({
  auth_info: {
    client_id: localStorage.getItem('client_id') || '',
    staff_id: localStorage.getItem('staff_id') || '',
    access_token: localStorage.getItem('access_token') || '',
    expires_in: localStorage.getItem('expires_in') || '',
    expiration: localStorage.getItem('expiration') || '',
    refresh_token: localStorage.getItem('refresh_token') || '',
    refresh_expires_in: localStorage.getItem('refresh_expires_in') || '',
    refresh_expiration: localStorage.getItem('refresh_expiration') || '',
  },
})

/**
 * getters
 */
const getters = {
  /**
   * User for Navigation guard
   */
  loggedIn(state) {
    return state.auth_info.access_token !== ''
  },

  /**
   * Get access token
   * @param {*} state
   * @returns
   */
  getAccessToken(state) {
    return state.auth_info.access_token
  },

  /**
   * Get refresh token
   * @param {*} state
   * @returns
   */
  getRefreshToken(state) {
    return state.auth_info.refresh_token
  },

  /**
   * Get auth info
   * @param {*} state
   * @returns state
   */
  authInfo(state) {
    return state.auth_info
  },
}

/**
 * mutations
 */
const mutations = {
  /**
   * Set user_info
   * @param {*} state
   * @param {*} data
   */
  setAuthInfo(state, data) {
    state.auth_info.client_id = data.clientId
    state.auth_info.staff_id = data.staffId
    state.auth_info.access_token = data.accessToken
    state.auth_info.expires_in = data.expiresIn
    state.auth_info.expiration = data.expiration
    state.auth_info.refresh_token = data.refreshToken
    state.auth_info.refresh_expires_in = data.refreshExpiresIn
    state.auth_info.refresh_expiration = data.refreshExpiration
  },

  /**
   * DetroyAuth state info
   * @param {*} state
   */
  destroyAuth(state) {
    state.auth_info.client_id = ''
    state.auth_info.staff_id = ''
    state.auth_info.access_token = ''
    state.auth_info.expires_in = ''
    state.auth_info.expiration = ''
    state.auth_info.refresh_token = ''
    state.auth_info.refresh_expires_in = ''
    state.auth_info.refresh_expiration = ''
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Login Function
   * @param {*} context
   * @param {*} credentials
   */
  retrieveAuth(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = {
        username: credentials.username,
        password: credentials.password,
        grantType: 'password',
      }

      axios
        .post(process.env.VUE_APP_LOGIN, params)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.isSuccess) {
            const authInfo = response.data.data

            // Set to local storage
            localStorage.setItem('client_id', authInfo.clientId)
            localStorage.setItem('staff_id', authInfo.staffId)
            localStorage.setItem('access_token', authInfo.accessToken)
            localStorage.setItem('expires_in', authInfo.expiresIn)
            localStorage.setItem('expiration', authInfo.expiration)
            localStorage.setItem('refresh_token', authInfo.refreshToken)
            localStorage.setItem('refresh_expires_in', authInfo.refreshExpiresIn)
            localStorage.setItem('refresh_expiration', authInfo.refreshExpiration)

            // Set to state
            context.commit('setAuthInfo', authInfo)
            resolve(response)
          } else {
            if (
              response.data.code === '1010' &&
              response.data.message === 'Username and Password and license No information found'
            ) {
              resolve(response)
            } else reject(response.data.message)
          }
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },

  /**
   * Refresh access token function
   * @param {*} context
   */
  refreshToken(context) {
    return new Promise((resolve, reject) => {
      const params = {
        refreshToken: context.getters.getRefreshToken,
        grantType: 'refresh_token',
      }

      axios
        .post(process.env.VUE_APP_LOGIN, params)
        .then(function (response) {
          if (response.data.isSuccess) {
            const authInfo = response.data.data

            // Set to local storage
            localStorage.setItem('client_id', authInfo.clientId)
            localStorage.setItem('staff_id', authInfo.staffId)
            localStorage.setItem('access_token', authInfo.accessToken)
            localStorage.setItem('expires_in', authInfo.expiresIn)
            localStorage.setItem('expiration', authInfo.expiration)
            localStorage.setItem('refresh_token', authInfo.refreshToken)
            localStorage.setItem('refresh_expires_in', authInfo.refreshExpiresIn)
            localStorage.setItem('refresh_expiration', authInfo.refreshExpiration)

            // Set to state
            context.commit('setAuthInfo', authInfo)
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  /**
   * Logout Function :: Clear state.auth, localStorage
   * @param {*} context
   */
  async destroyAuth(context) {
    if (context.getters.loggedIn) {
      const res = await axios.post(process.env.VUE_APP_REVOKE)

      if (res.data.isSuccess) {
        context.commit('destroyAuth')
        localStorage.clear()
        location.reload()
      } else {
        console.log(res)
        location.reload()
      }
    }
  },

  /**
   * Branch list
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  branchList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_BRANCH_LIST)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF LIST
   */
  staffList(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = {
        page: credentials.page,
        limit: credentials.limit,
        search: credentials.search,
        order: credentials.order,
        sort: credentials.sort,
      }

      axios
        .post(process.env.VUE_APP_USER_STAFF_LIST, params)
        .then((res) => {
          if (res.data.isSuccess) {
            resolve(res.data.data)
          }
        })
        .catch((err) => {
          reject(err)
          console.log(err)
        })
    })
  },

  /**
   * STAFF CREATE
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  staffCreate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API create staff params ::', credentials)

      const params = {
        staffId: credentials.staffId,
        staffName: credentials.staffName,
        staffSurname: credentials.staffSurname,
        issuedate: credentials.issuedate,
        expiredate: credentials.expiredate,
        zoneCode: credentials.zoneCode,
        phoneNumber: credentials.phoneNumber,
        email: credentials.email,
        username: credentials.username,
        password: credentials.password,
        license: credentials.license,
        businessCode: credentials.businessCode,
        group: credentials.group,
        rd: credentials.rd,
        rm: credentials.rm,
      }

      axios
        .post(process.env.VUE_APP_USER_STAFF_CREATE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF VIEW
   */
  staffView(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API staff view params ::', credentials)
      const params = credentials.staffId ? '?staffId=' + credentials.staffId : ''

      axios
        .get(process.env.VUE_APP_USER_STAFF_VIEW + params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF UPDATE
   */
  staffUpdate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API update staff ::', credentials)
      const params = {
        staffId: credentials.staffId,
        staffName: credentials.staffName,
        staffSurname: credentials.staffSurname,
        issuedate: credentials.issuedate,
        expiredate: credentials.expiredate,
        zoneCode: credentials.zoneCode,
        phoneNumber: credentials.phoneNumber,
        email: credentials.email,
        license: credentials.license,
        businessCode: credentials.businessCode,
        group: credentials.group,
        rd: credentials.rd,
        rm: credentials.rm,
      }

      axios
        .post(process.env.VUE_APP_USER_STAFF_UPDATE + '/' + credentials.staffId, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF DELETE
   */
  staffDelete(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API delete staff ::', credentials)
      const params = credentials

      axios
        .post(process.env.VUE_APP_USER_STAFF_DELETE, {
          staffId: params,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF VALIDATE
   */
  staffValidate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API staff validate ::', credentials)
      const params = credentials
      // if (credentials.staffId) {
      //   params = { staffId: credentials.staffId }
      //   console.log('API staff validate params staffId ::', credentials.staffId)
      // } else {
      //   console.log('API staff validate params username ::', credentials.username)
      //   params = { username: credentials.username }
      // }

      axios
        .post(process.env.VUE_APP_USER_STAFF_VALIDATE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF UPLOAD
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  staffUpload(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('file', credentials.file)

      axios
        .post(process.env.VUE_APP_USER_STAFF_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS LIST
   */
  cmsList(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = {
        page: credentials.page,
        limit: credentials.limit,
        search: credentials.search,
        order: credentials.order,
        sort: credentials.sort,
      }

      axios
        .post(process.env.VUE_APP_USER_CMS_LIST, params)
        .then((res) => {
          if (res.data.isSuccess) {
            resolve(res.data.data)
          }
        })
        .catch((err) => {
          reject(err)
          console.log(err)
        })
    })
  },

  /**
   * CMS CREATE
   */
  cmsCreate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API create cms params ::', credentials)

      const params = {
        staffId: credentials.staffId,
        staffName: credentials.staffName,
        staffSurname: credentials.staffSurname,
        issuedate: credentials.issuedate,
        expiredate: credentials.expiredate,
        zoneCode: credentials.zoneCode,
        phoneNumber: credentials.phoneNumber,
        email: credentials.email,
        username: credentials.username,
        password: credentials.password,
      }

      axios
        .post(process.env.VUE_APP_USER_CMS_CREATE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS UPDATE
   */
  cmsUpdate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API update cms ::', credentials)
      const params = {
        staffId: credentials.staffId,
        staffName: credentials.staffName,
        staffSurname: credentials.staffSurname,
        issuedate: credentials.issuedate,
        expiredate: credentials.expiredate,
        zoneCode: credentials.zoneCode,
        phoneNumber: credentials.phoneNumber,
        email: credentials.email,
      }

      axios
        .put(process.env.VUE_APP_USER_CMS_UPDATE + '/' + credentials.staffId, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS VIEW
   */
  cmsView(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API cms view params ::', credentials)
      const params = credentials.staffId ? '?staffId=' + credentials.staffId : ''

      axios
        .get(process.env.VUE_APP_USER_CMS_VIEW + params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS DELETE
   */
  cmsDelete(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API delete cms ::', credentials)
      const params = { staffId: credentials.staffId }

      axios
        .delete(process.env.VUE_APP_USER_CMS_DELETE, {
          data: params,
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS VALIDATE
   */
  cmsValidate(context, credentials) {
    return new Promise((resolve, reject) => {
      let params = ''
      console.log('API cms validate ::', credentials)

      if (credentials.staffId) {
        params = { staffId: credentials.staffId }
      } else {
        params = { username: credentials.username }
      }

      axios
        .post(process.env.VUE_APP_USER_CMS_VALIDATE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS UPLOAD
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  cmsUpload(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('file', credentials.file)

      axios
        .post(process.env.VUE_APP_USER_CMS_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * Organi LIST
   */
  organiList(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = {
        page: credentials.page,
        limit: credentials.limit,
        search: credentials.search,
        order: credentials.order,
        sort: credentials.sort,
      }
      axios
        .post(process.env.VUE_APP_CMS_ORGANI_LIST, params)
        .then((res) => {
          if (res.data.isSuccess) {
            console.log('Data from API:', res.data.data)
            resolve(res.data.data)
          }
        })
        .catch((err) => {
          reject(err)
          console.log('Invalid API ::', err)
        })
    })
  },
  async organiCreate(context, credentials) {
    try {
      const params = {
        name: credentials.name,
        referral_code: credentials.referral_code,
        ref1: credentials.ref1,
        ref2: credentials.ref2,
        image_logo: credentials.image_logo,
      }
      const res = await axios.post(process.env.VUE_APP_CMS_ORGANI_CREATE, params)
      if (res.status === 200 && res.data.isSuccess) {
        return res.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },
  organiView(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = credentials.id ? '/' + credentials.id : ''

      axios
        .get(process.env.VUE_APP_CMS_ORGANI_VIEW + params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  organiDelete(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = { id: credentials.organiId }
      console.log('API delete cms ::', params)

      axios
        .post(process.env.VUE_APP_CMS_ORGANI_DELETE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  async organiUpdate(context, credentials) {
    try {
      const params = {
        id: credentials.id,
        name: credentials.name,
        referral_code: credentials.referral_code,
        ref1: credentials.ref1,
        ref2: credentials.ref2,
      }
      if (credentials.image_logo) {
        params.image_logo = credentials.image_logo
      }
      const res = await axios.post(process.env.VUE_APP_CMS_ORGANI_UPDATE, params)
      if (res.status === 200 && res.data.isSuccess) {
        return res.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },
  organiDropdown() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_CMS_ORGANI_DROPDOWN)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  /**
   * UPLOAD PAYMENT
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  paymentUpload(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('file', credentials.file)

      axios
        .post(process.env.VUE_APP_UPLOAD_PAYMENT, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  fileUpload(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('file', credentials.file)

      axios
        .post(process.env.VUE_APP_RENEWAL_CMS_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        })
        .then((res) => {
          // เช็คสถานะการส่งคืน
          if (res.status === 200) {
            resolve(res) // ส่งข้อมูลกลับไปเพื่อให้คุณใช้งานต่อได้
          } else {
            reject(new Error('ไม่สามารถอัปโหลดไฟล์ได้')) // หรือจัดการกับสถานะอื่นๆ ตามที่เหมาะสม
          }
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.isSuccess === false) {
            // หากมีข้อผิดพลาดจากเซิร์ฟเวอร์
            const { data } = err.response.data
            const errorMessages = data.map((item) => item.error_message.join('\n'))
            reject(new Error(`เกิดข้อผิดพลาด: ${errorMessages.join('\n')}`))
          } else {
            reject(err) // จัดการข้อผิดพลาดอื่นๆ ที่อาจเกิดขึ้น
          }
        })
    })
  },
  fileUploadPaymentReplace(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('file', credentials.file)

      axios
        .post(process.env.VUE_APP_PAYMENT_REPLACE, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  fileUploadODDReplace(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('file', credentials.file)

      axios
        .post(process.env.VUE_APP_PAYMENT_ODD_REPLACE, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          responseType: 'blob',
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
